import { extendTheme } from "@chakra-ui/react"

const theme = extendTheme({
  breakpoints: {
    base: "0em",
    xs: "20em",
    sm: "30em",
    md: "48em",
    lg: "62em",
    xl: "80em",
    "2xl": "96em",
    "3xl": "120em",
    "4xl": "160em",
  },
  fonts: {
    body: "Noto Serif, serif",
    text: "Noto Serif, serif",
    heading: "Noto Serif, serif",
    button: "Noto Sans, sans-serif",
    input: "Noto Sans, sans-serif",
  },
  components: {
    Button: {
      baseStyle: {
        fontFamily: "Noto Sans, sans-serif",
      },
    },
    Text: {
      baseStyle: {
        fontFamily: "Noto Serif, serif",
      },
      variants: {
        sans: {
          fontFamily: "Noto Sans, sans-serif",
        },
      },
    },
    Heading: {
      baseStyle: {
        fontFamily: "Noto Serif, serif",
      },
    },
    Input: {
      baseStyle: {
        fontFamily: "Noto Sans, sans-serif",
      },
    },
    Checkbox: {
      baseStyle: {
        control: {
          border: "2px solid #E1E7EF !important",
          borderRadius: "2px",
          _checked: {
            bg: "bean.500",
            borderColor: "bean.500",
            _hover: {
              bg: "bean.600",
              borderColor: "bean.600",
            },
          },
        },
        icon: {
          color: "white",
        },
      },
    },
  },
  colors: {
    midnight: {
      "50": "#F6F6F9",
      "100": "#ECEDF2",
      "200": "#D5D7E2",
      "300": "#B0B4C9",
      "400": "#858BAB",
      "500": "#666E91",
      "600": "#515778",
      "700": "#434661",
      "800": "#3A3D52",
      "900": "#333647",
      "950": "#232430",
    },
    bean: {
      "50": "#f9e8e8",
      "100": "#f2c1c1",
      "200": "#eba3a3",
      "300": "#e27372",
      "400": "#d74848",
      "500": "#792424",
      "600": "#701e1e",
      "700": "#AD2829",
      "800": "#792424",
      "900": "#400d0d",
    },
    beansubtle: {
      500: "#FACECE",
    },
    textsubtle: {
      500: "#728197",
    },
    gin: {
      500: "#FFF9E1",
    }
  },
})

export default theme

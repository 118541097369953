// gatsby-browser.js
import React from "react";
import "./styles/global.css";
import { ChakraProvider } from "@chakra-ui/react";
import { ApolloProvider } from "@apollo/client";
import client from "./lib/apolloClient";
import theme from "./src/theme/theme";
import { I18nextProvider } from 'react-i18next';
import i18n from './src/i18n';

export const wrapRootElement = ({ element }) => (
  <ApolloProvider client={client}>
    <ChakraProvider theme={theme}>
      <I18nextProvider i18n={i18n}>
        {element}
      </I18nextProvider>
    </ChakraProvider>
  </ApolloProvider>
);

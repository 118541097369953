exports.components = {
  "component---src-components-templates-product-brand-tsx": () => import("./../../../src/components/templates/productBrand.tsx" /* webpackChunkName: "component---src-components-templates-product-brand-tsx" */),
  "component---src-components-templates-product-category-tsx": () => import("./../../../src/components/templates/productCategory.tsx" /* webpackChunkName: "component---src-components-templates-product-category-tsx" */),
  "component---src-components-templates-single-product-tsx": () => import("./../../../src/components/templates/singleProduct.tsx" /* webpackChunkName: "component---src-components-templates-single-product-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-shop-tsx": () => import("./../../../src/pages/shop.tsx" /* webpackChunkName: "component---src-pages-shop-tsx" */),
  "component---src-pages-terms-and-conditions-tsx": () => import("./../../../src/pages/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-tsx" */)
}

